.pac-container:after {
  background-image: none;
  display: none;
}

.pac-container {
  width: 280px !important;
  border: 2px solid #ff7163;
  @apply rounded-xl;
}

.pac-item {
  @apply cursor-pointer;
}