.nav-link {
  @apply ml-3 hover:text-black;
}

.active {
  @apply text-venturesqueOrange font-bold;
}

.btn {
  @apply rounded-full py-2 md:px-3 uppercase text-xs font-bold cursor-pointer tracking-wider;
}

.btn-red {
  @apply text-venturesqueOrange md:hover:bg-venturesqueOrange hover:text-red-500 md:hover:text-white transition ease-out duration-500;
}

.selected-btn {
  @apply bg-venturesqueOrange text-white transition ease-out duration-500;
}
