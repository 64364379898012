.team-card {
  @apply flex flex-col justify-center items-center mb-6;
}

.team-header {
  @apply my-1 text-xl text-gray-500;
}

.team-title {
  @apply block text-gray-400;
}
