@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import 'nav.css';
@import 'card.css';
@import 'about.css';
@import 'signup.css';
@import 'autocomplete.css';
@import 'form.css';

.title-semibold {
  @apply text-gray-600 text-4xl font-semibold;
}

